<template>
  <div
    v-if="isMounted"
    class="avatar-teacher"
    :class="mode === 'start-teaching-top' ? 'avatar-teacher--top': ''"
  >
    <div class="avatar-teacher__img">
      <nuxt-img
        v-if="imgSource(imgUrl)"
        :key="id"
        class="max-w-none rounded-full border-[0.5px] border-border-cart"
        :src="imgSource(imgUrl)"
        :alt="alt || title"
        :width="width"
        :height="height"
        loading="lazy"
        format="webp"
        placeholder
      />
    </div>
    <div v-if="showText" class="avatar-teacher__text">
      <p class="avatar-teacher__title text-overflow-dots w-[140px]">
        {{ title }}
      </p>
      <p class="avatar-teacher__subtitle">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>
<script>
import { isString } from 'lodash'
import detectDeviceMixin from '@helper/detectDeviceMixin'

export default {
  name: 'BaseAvatarTeacherُ',
  mixins: [detectDeviceMixin],
  props: {
    imgUrl: {
      type: [String, Object],
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'list',
      valid: ['list', 'start-teaching-top', 'start-teaching-testimonial', 'course-group']
    }
  },
  data () {
    return {
      isMounted: false
    }
  },
  computed: {
    sizes () {
      const obj = {
        list: 'xs:76px sm:76px md:128px lg:128px',
        card: 'xs:87px sm:87px md:124px lg:124px',
        'start-teaching-top': '64px',
        'start-teaching-testimonial': '64px',
        'course-group': '38px'
      }
      return obj[this.mode]
    },
    width () {
      const obj = {
        list: ['76', '128'],
        card: ['87', '124'],
        'start-teaching-top': ['64', '64'],
        'start-teaching-testimonial': ['64', '64'],
        'course-group': ['38', '38']
      }
      return this.isMobile ? obj[this.mode][0] : obj[this.mode][1]
    },
    height () {
      const obj = {
        list: ['76', '128'],
        card: ['87', '124'],
        'start-teaching-top': ['64', '64'],
        'start-teaching-testimonial': ['64', '64'],
        'course-group': ['38', '38']
      }
      return this.isMobile ? obj[this.mode][0] : obj[this.mode][1]
    },
    showText () {
      const modes = ['list', 'course-group', 'card']
      return !modes.includes(this.mode)
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    imgSource (img) {
      if (isString(img)) {
        return img
      }
      return img?.source?.jpg_128X128?.url || img?.jpg_128X128?.url ||
          img?.source?.jpg_80X80?.url || img?.jpg_80X80?.url
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-teacher {
  @apply flex items-center space-x-4;
  &__title {
    @apply text__caption-xs--bold lg:text__body-3-md--bold text-title;
  }
  &__subtitle {
    @apply text__caption-1-xs--regular lg:text__caption-xs--regular text-body-2;
  }
  &__text {
    @apply text-left;
  }
}
.avatar-teacher--top {
  @apply lg:flex-col lg:space-x-0;
  .avatar-teacher__text {
    @apply mt-0 lg:mt-4 text-left lg:text-center;
  }
  .avatar-teacher__title{
    @apply text__body-2-md--bold mt-1;
  }
  .avatar-teacher__subtitle{
    @apply  text__body-3-md--regular mt-1;
  }
}
</style>
