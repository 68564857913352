export function getRequestStepsName (state) {
  return state.requestRouteNameSteps
}
export function getSubmitRequestSteps (state) {
  return state.submitRequestSteps
}
export function getSubmitRequestCalendar (state) {
  return state.submitRequestCalendar
}
export function getPriceStepData (state) {
  return state.priceStepData
}
export function getLanguagesStepData (state) {
  return state.languagesStepData
}
export function getDescriptionStepData (state) {
  return state.descriptionStepData
}

export function getTrackingData (state) {
  return state.trackingData
}

export function getSelectedTutor (state) {
  return state.selectedTutor
}

export function getConfirmData (state) {
  return state.confirmData
}
