import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _78bb2b0a = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _6782c148 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _73154989 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _1ebce474 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _5e491cba = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _27cdee4e = () => interopDefault(import('../pages/UIKITS.vue' /* webpackChunkName: "pages/UIKITS" */))
const _2759f145 = () => interopDefault(import('../pages/world.vue' /* webpackChunkName: "pages/world" */))
const _4258272f = () => interopDefault(import('../pages/tutor-request/course.vue' /* webpackChunkName: "pages/tutor-request/course" */))
const _b79f0306 = () => interopDefault(import('../pages/tutor-request/calendar/_id.vue' /* webpackChunkName: "pages/tutor-request/calendar/_id" */))
const _34c4de87 = () => interopDefault(import('../pages/tutor-request/confirm/_id.vue' /* webpackChunkName: "pages/tutor-request/confirm/_id" */))
const _7f911dc3 = () => interopDefault(import('../pages/tutor-request/description/_id.vue' /* webpackChunkName: "pages/tutor-request/description/_id" */))
const _d479183c = () => interopDefault(import('../pages/tutor-request/languages/_id.vue' /* webpackChunkName: "pages/tutor-request/languages/_id" */))
const _7ccc2110 = () => interopDefault(import('../pages/tutor-request/price/_id.vue' /* webpackChunkName: "pages/tutor-request/price/_id" */))
const _41ddce14 = () => interopDefault(import('../pages/tutor-request/tracking/_id.vue' /* webpackChunkName: "pages/tutor-request/tracking/_id" */))
const _e4962578 = () => interopDefault(import('../pages/tutor/_name.vue' /* webpackChunkName: "pages/tutor/_name" */))
const _6c7ea480 = () => interopDefault(import('../dynamic-pages/tutor-list.vue' /* webpackChunkName: "" */))
const _976942f6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _49510738 = () => interopDefault(import('../../config/pages/auth/forget-password.vue' /* webpackChunkName: "pages/auth/forget-password" */))
const _3e78f366 = () => interopDefault(import('../../config/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _e3799150 = () => interopDefault(import('../../config/pages/auth/login-by-oauth.vue' /* webpackChunkName: "pages/auth/login-by-oauth" */))
const _4492fc46 = () => interopDefault(import('../../config/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _1f2715b9 = () => interopDefault(import('../../config/pages/auth/set-password.vue' /* webpackChunkName: "pages/auth/set-password" */))
const _4601332a = () => interopDefault(import('../../config/pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _84443568 = () => interopDefault(import('../../config/pages/auth/verify.vue' /* webpackChunkName: "pages/auth/verify" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/about-us",
    component: _78bb2b0a,
    name: "about-us___en"
  }, {
    path: "/en/contact-us",
    component: _6782c148,
    name: "contact-us___en"
  }, {
    path: "/en/faq",
    component: _73154989,
    name: "faq___en"
  }, {
    path: "/en/privacy-policy",
    component: _1ebce474,
    name: "privacy-policy___en"
  }, {
    path: "/en/terms",
    component: _5e491cba,
    name: "terms___en"
  }, {
    path: "/en/UIKITS",
    component: _27cdee4e,
    name: "UIKITS___en"
  }, {
    path: "/en/world",
    component: _2759f145,
    name: "world___en"
  }, {
    path: "/en/tutor-request/course",
    component: _4258272f,
    name: "tutor-request-course___en"
  }, {
    path: "/en/tutor-request/calendar/:id?",
    component: _b79f0306,
    name: "tutor-request-calendar-id___en"
  }, {
    path: "/en/tutor-request/confirm/:id?",
    component: _34c4de87,
    name: "tutor-request-confirm-id___en"
  }, {
    path: "/en/tutor-request/description/:id?",
    component: _7f911dc3,
    name: "tutor-request-description-id___en"
  }, {
    path: "/en/tutor-request/languages/:id?",
    component: _d479183c,
    name: "tutor-request-languages-id___en"
  }, {
    path: "/en/tutor-request/price/:id?",
    component: _7ccc2110,
    name: "tutor-request-price-id___en"
  }, {
    path: "/en/tutor-request/tracking/:id?",
    component: _41ddce14,
    name: "tutor-request-tracking-id___en"
  }, {
    path: "/en/tutor/:name?",
    component: _e4962578,
    name: "tutor-name___en"
  }, {
    path: "/en/tutor-list/:param1?/:param2?/:param3?/:param4?/:param5?",
    component: _6c7ea480,
    name: "tutor-list___en"
  }, {
    path: "/",
    component: _976942f6,
    name: "index"
  }, {
    path: "/en/auth/forget-password",
    component: _49510738,
    name: "auth-forget-password___en"
  }, {
    path: "/en/auth/login",
    component: _3e78f366,
    name: "auth-login___en"
  }, {
    path: "/en/auth/login-by-oauth",
    component: _e3799150,
    name: "auth-login-by-oauth___en"
  }, {
    path: "/en/auth/logout",
    component: _4492fc46,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/set-password",
    component: _1f2715b9,
    name: "auth-set-password___en"
  }, {
    path: "/en/auth/signup",
    component: _4601332a,
    name: "auth-signup___en"
  }, {
    path: "/en/auth/verify",
    component: _84443568,
    name: "auth-verify___en"
  }, {
    path: "/fr/auth/forget-password",
    component: _49510738,
    name: "auth-forget-password___fr"
  }, {
    path: "/fr/auth/login",
    component: _3e78f366,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/login-by-oauth",
    component: _e3799150,
    name: "auth-login-by-oauth___fr"
  }, {
    path: "/fr/auth/logout",
    component: _4492fc46,
    name: "auth-logout___fr"
  }, {
    path: "/fr/auth/set-password",
    component: _1f2715b9,
    name: "auth-set-password___fr"
  }, {
    path: "/fr/auth/signup",
    component: _4601332a,
    name: "auth-signup___fr"
  }, {
    path: "/fr/auth/verify",
    component: _84443568,
    name: "auth-verify___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
