import {
  REQUEST_COURSES, REQUEST_PRICE, REQUEST_LANGUAGES, REQUEST_CALENDAR,
  REQUEST_DESCRIPTION, REQUEST_CALENDAR_DAYS, REQUEST_TRACKING, REQUEST_CANCEL,
  REQUEST_CONFIRM
} from '@endpoints'
import {
  STORE_META,
  STORE_REQUEST_CALENDAR,
  STORE_REQUEST_TEACHER, STORE_REQUEST_SELECT_TUTOR,
  STORE_REQUEST_PRICE_STEP, STORE_REQUEST_TRACKING,
  STORE_REQUEST_LANGUAGES_STEP, STORE_REQUEST_DESCRIPTION_STEP
} from '../mutation-types'

export async function asyncDataCourses ({ commit }) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: REQUEST_COURSES.url
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
    }
  } catch (e) {
    console.log(e)
  }
}

export async function postCourse ({ commit, app, state }, payload) {
  try {
    const response = await this.$api.post({
      url: REQUEST_COURSES.url,
      payload
    })
    if (response && response?.data) {
      if (response.data.is_submitted) {
        commit(STORE_REQUEST_TEACHER, response.data.teacher_request_id)
      }
      return response
    }
  } catch (err) {
  }
}

export async function asyncDataPrice ({ commit, state }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: REQUEST_PRICE.url + payload
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      const stepData = {
        price_steps: data.price_steps,
        price: data.price,
        currency: data.currency,
        teacher_request_id: data.teacher_request_id
      }
      commit(STORE_REQUEST_PRICE_STEP, stepData)
      return data
    }
  } catch (e) {
  }
}

export async function postPrice ({ commit, app, state }, payload) {
  try {
    const response = await this.$api.post({
      url: REQUEST_PRICE.url,
      payload
    })
    if (response && response?.data) {
      commit(STORE_REQUEST_TEACHER, response.data.teacher_request_id)
      return response
    }
  } catch (err) {
  }
}

export async function asyncDataLanguages ({ commit, state }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: REQUEST_LANGUAGES.url + payload
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit(STORE_REQUEST_LANGUAGES_STEP, data)
    }
  } catch (e) {
  }
}

export async function postLanguages ({ commit, app, state }, payload) {
  try {
    const response = await this.$api.post({
      url: REQUEST_LANGUAGES.url,
      payload
    })
    if (response && response?.data) {
      commit(STORE_REQUEST_TEACHER, response.data.teacher_request_id)
      return response
    }
  } catch (err) {
  }
}

export async function asyncCalendarStep ({ commit, state }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: REQUEST_CALENDAR.url + payload.id,
      query: { calendar_page: payload.page }
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
    }
  } catch (e) {
  }
}

export async function fetchDataCalendar ({ commit, state }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: REQUEST_CALENDAR_DAYS.url,
      query: { calendar_page: payload.page, student_timezone: payload.student_timezone, teacher_request_id: payload.id }
    })
    const data = response?.data
    if (data) {
      // const calendar = JSON.parse(JSON.stringify(response?.data.calendar))
      const calendar = response?.data.calendar
      calendar.days = calendar.days.map((item) => {
        item.week_day_short = item.name_short
        return item
      })
      calendar.timezone = data.timezone.text
      if (data.student_calendar.length > 0) {
        const studentCalendar = data.student_calendar
        const mergArr = studentCalendar.map(item => item[0] + '_' + item[1])
        const selectedTimes = mergArr.flat()
        calendar.selected_datetimes = selectedTimes
      }
      commit('submitRequest/' + STORE_REQUEST_CALENDAR, calendar, { root: true })
      // const stepData = {
      //   timezone: data.timezone,
      //   student_calendar: calendar.selected_datetimes ? calendar.selected_datetimes : []
      // }
      // commit('submitRequest/' + STORE_REQUEST_CALENDAR_STEP, stepData, { root: true })
      return data
    }
  } catch (e) {
  }
}

export async function postCalendar ({ commit, app, state }, payload) {
  try {
    const response = await this.$api.post({
      url: REQUEST_CALENDAR.url,
      payload
    })
    if (response && response?.data) {
      commit(STORE_REQUEST_TEACHER, response.data.teacher_request_id)
      return response
    }
  } catch (err) {
  }
}

export async function asyncDataDescription ({ commit, state }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: REQUEST_DESCRIPTION.url + payload
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit(STORE_REQUEST_DESCRIPTION_STEP, data)
    }
  } catch (e) {
  }
}

export async function postDescription ({ commit, app, state }, payload) {
  try {
    const response = await this.$api.post({
      url: REQUEST_DESCRIPTION.url,
      payload
    })
    if (response && response?.data) {
      commit(STORE_REQUEST_TEACHER, response.data.teacher_request_id)
      return response
    }
  } catch (err) {
  }
}

export async function asyncTracking ({ commit, state }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: REQUEST_TRACKING.url + payload
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      data.student_cancel_reasons = data.student_cancel_reasons.map((item) => {
        item.name = item.text
        return item
      })
      commit(STORE_REQUEST_TRACKING, data)
    }
  } catch (e) {
  }
}

export async function cancelRequest ({ commit, app, state }, payload) {
  try {
    const response = await this.$api.post({
      url: REQUEST_CANCEL.url,
      payload
    })
    if (response && response?.data) {
      return response
    }
  } catch (err) {
  }
}

export async function selectTutor ({ commit, app, state }, payload) {
  try {
    await commit(STORE_REQUEST_SELECT_TUTOR, payload)
    return 'success'
  } catch (err) {
  }
}
export async function confirmRequest ({ commit, app, state }, payload) {
  try {
    const response = await this.$api.post({
      url: REQUEST_CONFIRM.url,
      payload
    })
    if (response && response?.data) {
      return response
    }
  } catch (err) {
  }
}
export async function asyncDataConfirm ({ commit, state }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: REQUEST_CONFIRM.url + payload
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      const teacher = JSON.parse(JSON.stringify(data.teacher))
      teacher.course_name = data.course_name
      commit(STORE_REQUEST_SELECT_TUTOR, teacher)
    }
  } catch (e) {
  }
}
