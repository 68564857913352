import {
  STORE_REQUEST_CALENDAR, STORE_REQUEST_CONFIRM,
  STORE_REQUEST_TEACHER, STORE_REQUEST_PRICE_STEP, STORE_REQUEST_LANGUAGES_STEP,
  STORE_REQUEST_DESCRIPTION_STEP, STORE_REQUEST_TRACKING, STORE_REQUEST_SELECT_TUTOR
} from '../mutation-types'

export default {
  [STORE_REQUEST_TEACHER] (state, payload) {
    state.requestTeacher.teacher_request_id = payload
  },
  [STORE_REQUEST_CALENDAR] (state, payload) {
    state.submitRequestCalendar = payload
  },
  [STORE_REQUEST_PRICE_STEP] (state, payload) {
    state.priceStepData = payload
  },
  [STORE_REQUEST_LANGUAGES_STEP] (state, payload) {
    state.languagesStepData = payload
  },
  [STORE_REQUEST_DESCRIPTION_STEP] (state, payload) {
    state.descriptionStepData = payload
  },
  [STORE_REQUEST_TRACKING] (state, payload) {
    state.trackingData = payload
  },
  [STORE_REQUEST_SELECT_TUTOR] (state, payload) {
    state.selectedTutor = payload
  },
  [STORE_REQUEST_CONFIRM] (state, payload) {
    state.confirmData = payload
  }
}
