<template>
  <div class="base-textarea" :class="{'cursor-not-allowed': disabled}">
    <div class="base-textarea__title">
      {{ title }}
    </div>
    <div class="base-textarea__wrapper" :class="[messageTypeBorder]">
      <div>
        <span v-if="$slots.left" class="base-textarea__slot-left">
          <slot name="left">
            <BaseIcon v-if="iconLeft" :name="iconLeft" size="xs" color="label-text" />
          </slot>
        </span>
      </div>
      <div class="relative flex-1">
        <textarea
          :id="name"
          autocomplete="off"
          :name="name"
          :value="value"
          :class="[inputClass, {'base-textarea__input--dense': dense}]"
          class="base-textarea__input"
          v-bind="$attrs"
          :disabled="disabled"
          @input="setValue($event.target.value)"
          @focus="focusInput"
          @blur="blurInput"
          @keyup.enter="$emit('keyupEnter')"
        />
        <label
          v-if="!isFocused && !value"
          :for="name"
          class="base-textarea__placeholder"
          :class="disabled ? 'cursor-not-allowed': 'cursor-text'"
        >
          <span class="flex items-center space-x-2">
            <span>{{ placeholder }}</span>
          </span>
        </label>
      </div>
      <span v-if="iconRight" class="base-textarea__icon-right">
        <BaseIcon :name="iconRight" color="label-text" @click="$emit('iconRightClicked')" />
      </span>
    </div>
    <div
      v-if="message"
      :class="messageTypeText"
      class="base-textarea__message"
    >
      <span class="flex items-center space-x-2">
        <BaseIcon name="info" size="xs" :color="messageType" />
        <span>{{ message }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTextarea',
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    name: {
      type: String,
      default: 'name1'
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    messageType: {
      type: String,
      default: '',
      valid: ['warning', 'error']
    },
    value: {
      type: [String, Number],
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    iconLeft: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFocused: false
    }
  },
  computed: {
    messageTypeText () {
      return this.messageType === 'warning' ? 'text-warning' : 'text-error'
    },
    messageTypeBorder () {
      if (!this.message) {
        return ''
      } else {
        return 'base-textarea__wrapper--' + this.messageType
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'setUpdateValue'
    }
  },
  methods: {
    focusInput () {
      this.isFocused = true
      this.$emit('emitFocus')
    },
    blurInput () {
      this.isFocused = false
      this.$emit('emitBlur')
    },
    setUpdateValue (value) {
      if (value !== '') {
        this.$emit('update', value)
      }
    },
    setValue (value) {
      this.$emit('update', value)
    }
  }
}
</script>

<style scoped lang="scss">
.base-textarea {
  @apply relative;
  &__wrapper {
    @apply relative flex items-center space-x-3 border rounded-2xl border-border-text-field py-3 px-1;
    &--warning {
      @apply border-warning;
      &:focus,&:focus-visible {
        @apply  border-warning;
      }
    }
    &--error {
      @apply border-error;
      &:focus,&:focus-visible {
        @apply  border-error;
      }
    }
  }
  &__input {
    @apply bg-transparent  lg:min-h-[64px] min-h-[48px] w-full
    lg:text__text-input-md--regular text__text-input-md--regular text-title;
    &--dense {
      @apply lg:h-12;
    }
    &:focus,&:focus-visible {
      @apply outline-none border-primary;
    }
    &:-webkit-autofill {
      &+ label {
        @apply hidden;
      }
    }
    &[disabled=disabled]{
      @apply pointer-events-none bg-border-cart border-border-cart;
    }
  }
  &__title {
    @apply m-1 mb-2 mt-0 lg:text__title-input-md--regular text__title-input-md--regular text-body-2;
  }
  &__placeholder {
    @apply absolute top-0 lg:text__placeholder-input-md--regular
    text__caption-xs--regular text-label-text left-0;
  }
  &__message {
    @apply absolute mt-1 text__caption-xs--regular;
  }
  &__icon-right {
    @apply  inline-flex pr-4 cursor-pointer;
  }
  &__slot-left {
    @apply inline-flex;
  }
}
</style>
